h3,
h4 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description {
    width: 55%;
    min-width: 350px;
}

.width {
    width: 30%;
    min-width: 300px;
}
.w-10 {
    width: 10% !important;
}
.w-20 {
    width: 20% !important;
}
.w-30 {
    width: 30% !important;
}
.w-40 {
    width: 40% !important;
}
.w-50 {
    width: 50% !important;
}
.w-60 {
    width: 60% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}
.w-90 {
    width: 90% !important;
}
.w-full {
    width: 100% !important;
}
.h-full {
    height: 100% !important;
}

.size-80 {
    width: 80px;
    height: 80px;
}

.flex-ct {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-check-input:checked {
    background-color: #00cd9b !important;
    border-color: #00cd9b !important;
}

@media screen and (max-width: 1150px) {
    .size-80 {
        width: 70px;
        height: 70px;
    }
}
.sectionBtn {
    width: 230px;
}


.tabPart {
    align-items: end;
}

.tabTitle {
    width: 9rem;
    text-align: center;
}

@media screen and (max-width: 1470px) {
    .tabPart {
        flex-direction: column;
        justify-content: center;
        align-items: center !important;
    }

    .namePart {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 1000px) {
    .namePart {
        flex-direction: column;
        align-items: center;
    }

    .tabTitle {
        width: 6.5rem;
    }

    .tabName {
        margin-bottom: 1rem;
    }
}

.tabName {
    font-size: 1.5rem;
}

.nav-item {
    .nav-link {
        background-color: #EEE;
        color: #333;
        padding: 1% 3%;
        text-decoration: none;
        white-space: nowrap;

        &.active {
            background-color: #FFF;
            color: #000;
        }
    }
}

.history {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.history:active {
    white-space: wrap;
    overflow: visible;
}

.instruction .ql-container.ql-snow {
    height: 37px;
    border: 1px solid black;
}
.tab-pane {
    margin-left: 0!important;
    
}