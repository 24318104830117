.firstGrid {
    padding-right: 100px;
}
.btn-circle.btn-sm { 
    width: 27px; 
    height: 27px; 
    padding: 6px 0px; 
    border-radius: 15px; 
    font-size: 8px; 
    align-self: center;
    text-align: center; 
    margin-top:-9px;
} 
.btn-circle.btn-sm:active{ 
   background-color: red;
} 
.dataInfor {
    padding: 1px 0px 0px 7px!important;
    font-size: 14px!important;
}
.table-responsive {
    border: 1px solid #e7e7e7;
    
}
#data-table-basic {
    width: 100%;
    margin: 0!important;
    border-collapse: collapse!important;
    border:0!important;
}
.circleButton {
    align-self: center;
}
.form-check {
    max-width: 228px;
    font-size: 14px!important;
}
.checkInput {
    max-width: 186px;
    margin-left:-10px;

}
.tableheader {
    font-size: 14px;
}
thead {
    margin-top: -10px!important;
}