/* Add your global styles if needed */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.card {
    width: 80%;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-datatable {
    padding: 20px;
}

.dataTables_wrapper {
    margin-top: 20px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.form-search {
    width: 70%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.custom-input-group {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
}

.input-group-text {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
    border-radius: 0 5px 5px 0;
}

.table-responsive {
    margin-top: 20px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    overflow-x: auto;
}

.anucios-table {
    width: 100%;
    border-collapse: collapse;
}

.anuncios-data {
    display: flex;
    flex-wrap: wrap;
}

.anuncios-data>div {
    flex: 1;
    padding: 15px;
    text-align: center;
   
    border-bottom: 1px solid #dee2e6;
}

.anuncios-data>div:last-child {
    border-right: none;
}

.anuncios-data>div.dropdown {
    text-align: end;
}

.dropdown-toggle {
    cursor: pointer;
}

.dropdown-menu {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
}

.dropdown-item {
    cursor: pointer;
    padding: 10px;
    color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
    .card {
        width: 95%;
    }

    .form-search {
        width: 100%;
        margin-top: 10px;
    }
}